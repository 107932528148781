import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PageTitle from '../components/Blocks/PageTitle'
import { Helmet } from 'react-helmet'

const CareersTemplateV2 = ({
  title,
  content,
  faq,
  loadedData,
  arrayOfDepartments,
  arrayOfLocations,
  searchInput,
  departmentChange,
  locationChange,
  openModal,
  closeModal,
  shareLink,
}) => (
  <div className="w-full h-full">
    <PageTitle title={title} />
    <div
      className={'transition-all page-content'}
      dangerouslySetInnerHTML={{ __html: content }}
    />

    <div className="transition-all page-content">
      {loadedData && (
        <div className="loaded-jobs">
          <div className="jl-header">
            <div className="jl-counter"></div>
            <div className="jl-controls">
              <div className="jl-control-search">
                <input
                  type="text"
                  id="search"
                  name="jl-search"
                  placeholder="Search"
                  onChange={e => searchInput(e)}
                  onKeyUp={e => searchInput(e)}
                />
              </div>
              {arrayOfDepartments && (
                <div className="jl-control-departments">
                  <select
                    id="filter-departments"
                    onChange={e => departmentChange(e)}
                  >
                    <option value="all">All Departments</option>
                    {arrayOfDepartments.map(dep => (
                      <option key={dep ? dep : ''} value={dep ? dep : ''}>
                        {dep ? dep : 'Unspecified'}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {arrayOfLocations && (
                <div className="jl-control-locations">
                  <select
                    id="filter-locations"
                    onChange={e => locationChange(e)}
                  >
                    <option value="all">All Locations</option>
                    {arrayOfLocations.map(loc => (
                      <option key={loc ? loc : ''} value={loc}>
                        {' '}
                        {loc ? loc : 'Unspecified'}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          <div className="jl-jobs-listing">
            <table>
              <thead>
                <tr>
                  <th>Job</th>
                  <th className="hide-mobile">Post Date</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {loadedData.map(job => (
                  <>
                    {job['visible'] && (
                      <tr
                        key={job['id']}
                        className="jl-job-row"
                        data-companytitle={job['text']}
                        data-companydepartment={job['categories']['department']}
                        data-joblocation={job['categories']['location']}
                      >
                        <td className="jl-title">
                          <a
                            href="#job"
                            className="open-modal"
                            data-jobid={job['id']}
                            onClick={e => openModal(e)}
                          >
                            {job['text']}
                          </a>
                          <div className="show-mobile">
                            {new Date(job['createdAt'])
                              .toISOString()
                              .slice(0, 10)}
                          </div>
                        </td>
                        <td className="jl-date hide-mobile">
                          {new Date(job['createdAt'])
                            .toISOString()
                            .slice(0, 10)}
                        </td>
                        <td className="jl-location">
                          <div className="jl-location-details">
                            <div className="jl-location-title">
                              {job['categories']['team']}
                            </div>
                            <div className="jl-location-address">
                              {job['categories']['location']}
                            </div>
                          </div>
                        </td>
                        {job['modal'] && (
                          <td className="jl-details-hidden">
                            <div
                              className="modal"
                              data-outsite={true}
                              onClick={e => closeModal(e)}
                            >
                              <div className="jl-modal modal-content">
                                <div className="jl-details-header modal-header">
                                  <span
                                    className="close"
                                    data-outsite={true}
                                    onClick={e => closeModal(e)}
                                  >
                                    &times;
                                  </span>
                                  <div className="jl-details-title">
                                    {job['text']}
                                  </div>
                                  <div className="jl-details-location">
                                    {job['categories']['location']}
                                  </div>
                                  <div className="jl-details-apply-button">
                                    <a
                                      className="button apply-corner-button"
                                      href={job['applyUrl']}
                                      target="_blank"
                                    >
                                      Apply
                                    </a>
                                  </div>
                                </div>
                                <div className="jl-details-body modal-body">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: job['description'],
                                    }}
                                  />
                                  <a
                                    className="button apply-button"
                                    href={job['applyUrl']}
                                    target="_blank"
                                  >
                                    Apply
                                  </a>
                                </div>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3" className="jl-foot-td">
                    <div className="jl-foot-header">
                      Not Finding What You're Looking For?
                    </div>
                    <div className="jl-foot-p">
                      Share your information and we will contact you if new
                      opportunities fitting your qualifications become available
                    </div>
                    <div className="jl-foot-button">
                      <a
                        className="button wp-block-button__link has-black-color has-text-color"
                        href={shareLink}
                        target="_blank"
                      >
                        Share Your Information
                      </a>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </div>
  </div>
)

const Page = ({ data, pageContext }) => {
  const { wpPage: page } = data

  const [loaded, setLoadedData] = useState([])
  const [arrayOfDepartments, setArrayOfDepartments] = useState([])
  const [arrayOfLocations, setArrayOfLocations] = useState([])
  const [filters, setArrayOfFilters] = useState({
    search: '',
    department: '',
    location: '',
  })

  useEffect(() => {
    const careersUrl = page.careerFields.apiLink
    get_data(careersUrl);
  }, [])

  async function get_data(careersUrl) {

    fetch(careersUrl)
    .then(function(response){ return response.json(); })
    .then(function(data) {
        const items = data;
        const departments = []
        const locations = []
        items.forEach(job => {
          job['visible'] = true
          job['modal'] = false
          departments.push(job['categories']['department'])
          locations.push(job['categories']['location'])
        })
      setLoadedData([...items])
      setArrayOfDepartments(departments.filter(onlyUnique))
      setArrayOfLocations(locations.filter(onlyUnique))

    })

  }

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index
  }

  function openModal(e) {
    const element = loaded.find(o => o.id == e.target.dataset.jobid);
    element['modal'] = true
    setLoadedData([...loaded])
  }

  function closeModal(e) {
    if (!e.target.dataset.outsite) {
      return
    }
    loaded.map(o => {
      o.modal = false
    })
    setLoadedData([...loaded])
  }

  function searchInput(e) {
    filters['search'] = e.target.value
    checkForFilters()
  }

  function departmentChange(e) {
    filters['department'] = e.target.value
    checkForFilters()
  }

  function locationChange(e) {
    filters['location'] = e.target.value
    checkForFilters()
  }

  function checkForFilters() {
    loaded.forEach(elem => {
      if (
        elem['text'].toLowerCase().includes(filters.search.toLowerCase()) &&
        (elem['categories']['department'] == filters.department ||
          filters.department == 'all' ||
          !filters.department) &&
        (elem['categories']['location'] == filters.location ||
          filters.location == 'all' ||
          !filters.location)
      ) {
        elem['visible'] = true
        //elem.style.display = 'table-row'
      } else {
        elem['visible'] = false
        //elem.style.display = 'none'
      }
    })
    setLoadedData([...loaded])
  }

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} title={page.title} />
      <Helmet>
        <script type="application/ld+json">
          {`[{"@context":"https://schema.org","@type":"CareersPage","mainEntityOfPage":{"@type":"WebPage","@id":"https://www.dmautoleasing.com/careers/careers-staging/"},"url":"https://www.dmautoleasing.com/careers-staging/","headline":"Careers at D&M | D&M Auto Leasing","description":"D&M Auto Leasing has several thousands of satisfied customers. With three locations in the DFW area we make auto leasing easy.","publisher":{"@type":"Organization","name":"D&amp;M Auto Leasing","url":"https://www.dmautoleasing.com"}},{"@context":"https://schema.org","@graph":[{"@context":"https://schema.org","@type":"SiteNavigationElement","@id":"https://www.dmautoleasing.com/#Main Menu","name":"Home","url":"https://www.dmautoleasing.com/"}]},{"@context":"https://schema.org","@type":"Review","@id":"https://www.dmautoleasing.com/about/#Review","itemReviewed":{"@type":"LocalBusiness","image":[]},"name":"D&amp;M Auto Leasing","author":{"@type":"Person","name":"Darren Hasley"}}]`}
        </script>
      </Helmet>
      <CareersTemplateV2
        page={page}
        title={page.title}
        content={page.content}
        faq={page.faq}
        loadedData={loaded}
        arrayOfDepartments={arrayOfDepartments}
        arrayOfLocations={arrayOfLocations}
        searchInput={searchInput}
        departmentChange={departmentChange}
        locationChange={locationChange}
        openModal={openModal}
        closeModal={closeModal}
        shareLink={page.careerFields.shareYourInformationLink}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query CareersPageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
      careerFields {
        apiLink
        shareYourInformationLink
      }
    }
  }
`